@import "./variables.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap");

@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;700&display=swap');

@import-normalize;

html {
    font-family: "Lato", sans-serif;
}

body {
    min-height: 100vh;
    background: #c5c7bb;
    background-size: cover;
}

html,
body,
div,
p {
    margin: 0;
    padding: 0;
}

input,
button {
    font-family: "Lato", sans-serif;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    background: white;
    border-radius: 1em;
    border: 1px solid black;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
}
